import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  { path: "/", component: () => import("../views/Home.vue") },
  { path: "/konferansier", component: () => import("../views/Page.vue"), props: { id: "konferansier" } },
  { path: "/energisk", component: () => import("../views/Page.vue"), props: { id: "energisk" } },
  { path: "/seoikult", component: () => import("../views/Page.vue"), props: { id: "seoikult" } },
  { path: "/vannvittig", component: () => import("../views/Page.vue"), props: { id: "vannvittig" } },
  { path: "/universet", component: () => import("../views/Page.vue"), props: { id: "universet" } },
  { path: "/joss", component: () => import("../views/Page.vue"), props: { id: "joss" } },
  { path: "/kontakt/:id", component: () => import("../views/Contact.vue"), props: true }
];

const router = new VueRouter({
  mode: "history",
  routes,

  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      let element = document.querySelector(to.hash);
      if (element) {
        return window.scrollTo({
          top: element.offsetTop - 100,
          behavior: "smooth"
        });
      } else {
        setTimeout(function() {
          element = document.querySelector(to.hash);

          return window.scrollTo({
            top: element.offsetTop - 100,
            behavior: "smooth"
          });
        }, 500);
      }
    } else {
      return window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
  }
});

export default router;
